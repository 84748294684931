<template>
  <div class="associated">
    <div class="associated_top">
      <div style="flex: 1"></div>
      <div class="associated_img">
        <img :src="companyinfo.companyLogo" alt="" />
        <span style="padding-left: 10px">{{ companyinfo.companyFullName }}</span>
      </div>
      <div style="flex: 1" class="account_cycle" @click="beforeAccountDialog(id)">
        <img
          src="../../../assets/image/Group-1.png"
          alt=""
          style="width: 16px; height: 16px; margin-right: 10px; vertical-align: top"
        />
        账号周期：{{ companyinfo.beginTime }}至{{ companyinfo.deadline }}
        <span v-if="companyinfo.prompt" style="color: #fd702b; width: 80px; display: inline-block">
          <img
            src="../../../assets/image/Group.png"
            alt=""
            style="width: 16px; height: 16px; margin-right: 10px; vertical-align: top"
          />即将到期</span
        >
        <span v-else style="width: 80px; display: inline-block"></span>
      </div>

      <!-- <div style="display: flex; padding-top: 10px">
        <div class="img_commers" style="padding: 0px 10px">
          <div class="color_img">
            <img src="../../../assets/image/jr-icon-poeple.png" alt="" />
          </div>
          <span class="name"> {{ secretaryGeneralName }}</span>
        </div>

        <div class="img_commer" style="padding: 0px 10px">
          <div class="color_img">
            <img src="../../../assets/image/jr-icon-commerce.png" alt="" />
          </div>
          <span class="name"> {{ chamberCommerceName }}</span>
        </div>
      </div> -->
    </div>
    <div class="switch_tab">
      <div style="padding-left: 20px">
        <el-button type="primary" @click="addaccount()">新增</el-button>
      </div>
      <div class="label_management">
        <el-table
          :data="listData"
          stripe
          style="width: 100%"
          class="in_table"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          <el-table-column label="编号" align="center" prop="id"></el-table-column>
          <el-table-column label="微信头像" align="center">
            <template v-slot="{ row }">
              <!-- <img :src="row.headImgUrl" width="42" height="42" alt="" /> -->
              <el-image
                v-if="row.headImgUrl"
                slot="reference"
                :src="row.headImgUrl"
                style="width: 40px; height: 40px; border-radius: 5px"
                fit="fit"
              ></el-image>
              <el-image
                v-else
                slot="reference"
                src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/202302061802.png"
                style="width: 40px; height: 40px; border-radius: 5px"
                fit="fit"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column label="微信昵称" prop="" align="center">
            <template v-slot="{ row }">
              <span>{{ row.nickName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="姓名" prop="" align="center">
            <template v-slot="{ row }">
              <span>{{ row.realName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="性别" prop="" align="center">
            <template v-slot="{ row }">
              <span v-if="row.sex == 1">男</span>
              <span v-if="row.sex == 2">女</span>
            </template>
          </el-table-column>
          <el-table-column label="职位" prop="position" align="center"></el-table-column>

          <el-table-column label="手机号" prop="phone" align="center"></el-table-column>
          <el-table-column label="所在城市" prop="address" align="center"></el-table-column>

          <el-table-column label="账号类型" prop="phone" align="center">
            <template v-slot="{ row }">
              <span
                v-if="row.accountAccess == 2"
                style="color: #4e93fb"
                @click="beforeAccountTypeDialog('子账号', row.id)"
                >子账号</span
              >
              <span
                v-else-if="row.accountAccess == 1"
                style="color: #f79646"
                @click="beforeAccountTypeDialog('主账号', row.id)"
                >主账号</span
              >
            </template>
          </el-table-column>
          <el-table-column label="名片" align="center">
            <template v-slot="{ row }">
              <img
                class="business_card_img"
                v-for="(i1, i2) in row.businessCards"
                :key="i2"
                :src="i1"
                width="92"
                height="42"
                alt=""
              />
            </template>
          </el-table-column>
          <el-table-column label="创建时间" prop="createTime" align="center" sortable="">
            <template v-slot="{ row }">
              <div>{{ row.createTime }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
            <template v-slot="{ row }">
              <el-button style="color: #146aff" type="text" @click="isFreeze(row)">{{
                row.isFreeze ? '激活' : '冻结'
              }}</el-button>
              <!-- <el-button style="color: #146aff" type="text" @click="userCertificate(scope.row)"
                >发短信
              </el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <!-- <div class="new_page">
                  <el-pagination :current-page="queryInfo.pageNum" :page-sizes="[10, 30, 50]"
                      :page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"
                      @size-change="handleSizeChange" @current-change="handleCurrentChange" />
              </div> -->
      </div>
    </div>
    <el-dialog
      title="账号周期"
      :visible.sync="accountDialogVisible"
      width="527px"
      class="account_dialog"
      :before-close="afteraccountDialogHandle"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="账号周期" prop="date">
          <el-date-picker
            value-format="yyyy-mm-dd"
            v-model="ruleForm.date"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            required
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>

      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        <el-button @click="afteraccountDialogHandle">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="账号类型"
      :visible.sync="accountTypeDialogVisible"
      width="527px"
      class="account_dialog"
    >
      <el-form label-width="100px" ref="accountTypeDialog">
        <el-form-item label="账号类型" prop="date">
          <el-select v-model="accountType" clearable placeholder="请选择" style="width: 350px">
            <el-option label="主账号" value="主账号"> </el-option>
            <el-option value="子账号"> </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="submitAccountTypeForm('accountTypeDialog')"
          >确 定</el-button
        >
        <el-button @click="accountTypeDialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="新增"
      :visible.sync="accountTypeDialogVisibles"
      width="627px"
      class="account_dialog"
    >
      <el-form label-width="100px" :model="adminUser" :rules="adminrules" ref="accountTypeDialogs">
        <div class="input_width">
          <el-form-item label="真实姓名:" prop="realName">
            <el-input v-model="adminUser.realName" placeholder="请输入真实姓名" />
          </el-form-item>

          <el-form-item label="用户性别:" prop="sex">
            <el-radio-group v-model="adminUser.sex">
              <el-radio :label="1">先生</el-radio>
              <el-radio :label="2">女士</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="所处职位:" prop="position">
            <el-radio-group v-model="adminUser.position">
              <el-radio :label="'CEO'">CEO</el-radio>
              <el-radio :label="'CIO'">CIO</el-radio>
              <el-radio :label="'IT负责人'">IT负责人</el-radio>
              <el-radio :label="'项目负责人'">项目负责人</el-radio>
              <el-radio :label="'其他'">其他</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="联系电话:" prop="phone">
            <el-input v-model="adminUser.phone" placeholder="请输入手机号" />
          </el-form-item>
        </div>
        <el-form-item label="所在城市:" prop="area">
          <div style="display: flex">
            <el-select v-model="adminUser.province" placeholder="请选择省份" @change="citychange">
              <el-option
                v-for="item in operatingList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <div style="display: inline-block; width: 10px"></div>
            <el-select v-model="adminUser.area" placeholder="请选择地区">
              <el-option
                v-for="item in operatingLists"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="账号类型:" prop="partnerType">
          <el-radio-group v-model="adminUser.accountAccess">
            <el-radio :label="1">主账号</el-radio>
            <el-radio :label="2">子账号</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="submitAccountTypeForms()">确 定</el-button>
        <el-button @click="accountTypeDialogVisibles = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  updateByPassAccountAccess,
  updateAccountAccess,
  updateCompanyAccountCycle,
  getCorrelateUser,
  getcompany,
  companyDetail,
  userCertificate,
  addinsetUser,
  isFreeze
} from '@/api/datement'
import { getByFatherIdt, getProvince } from '@/api/demand'
export default {
  name: 'CorrelateUser',
  computed: {
    id() {
      return this.$route.query.id
    },
    secretaryGeneralName() {
      return this.$route.query.secretaryGeneralName
    },
    chamberCommerceName() {
      return this.$route.query.chamberCommerceName
    }
  },
  data() {
    return {
      ruleForm: {
        date: null
      },
      rules: {
        date: [{ required: true, message: '请选择账号周期', trigger: 'blur' }]
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      value2: '',
      accountDialogVisible: false,
      accountTypeDialogVisible: false,
      accountType: '主账号',
      queryInfo: {
        id: this.$route.query.id,
        orderByParam: '',
        pageNum: 1,
        pageSize: 10
      },
      listData: [],
      total: 0,
      companyinfo: {},
      accountTypeDialogVisibles: false,
      adminUser: {
        companyId: null,
        accountAccess: null,
        area: null,
        phone: null,
        position: null,
        province: null,
        realName: null,
        sex: 1
      },
      adminrules: {
        realName: [{ required: true, message: '请输入真实姓名', trigger: 'blur' }],
        position: [{ required: true, message: '请勾选所处职位', trigger: 'change' }],
        area: [{ required: true, message: '请选择地区', trigger: 'change' }],

        phone: [
          { required: true, message: '请输入企业电话', trigger: 'blur' },
          // 这个只能验证手机号
          // { pattern:/^0{0,1}(13[0-9]|15[7-9]|153|156|18[7-9])[0-9]{8}$/, message: "请输入合法手机号", trigger: "blur" }
          {
            pattern:
              /((13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8})|^(0[0-9]{2,3}-)?([2-9][0-9]{0,9})(-[0-9]{1,9}){0,9}$/,
            message: '请输入合法手机号/电话号',
            trigger: 'blur'
          }
        ]
      },
      operatingLists: [],
      operatingList: []
    }
  },
  created() {
    this.getCorrelateUser()
    this.getcompany()
    this.incity()
  },
  methods: {
    //进去城市
    async getByFatherIdt(id) {
      const res = await getByFatherIdt({ provinceCode: id })
      if (res.data.resultCode == 200) {
        this.operatingLists = res.data.data
      }
    },
    //城市
    async incity() {
      const res = await getProvince()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
    },
    citychange(item) {
      this.operatingList.forEach((o) => {
        if (this.adminUser.province == o.name) {
          this.adminUser.area = ''
          if (item == '全部') {
            this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
            console.log(this.operatingLists, 'this.operatingLists ')
          } else {
            this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
          }

          console.log(item, 'item', this.operatingList)
          // this.getByFatherIdt(o.code)
        }
      })
      // this.search()
    },
    submitAccountTypeForms() {
      this.adminUser.companyId = this.id
      this.$refs['accountTypeDialogs'].validate((valid) => {
        if (valid) {
          addinsetUser(this.adminUser).then(({ data: res }) => {
            if (res.resultCode === 200) {
              this.getCorrelateUser()
              this.accountTypeDialogVisibles = false
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    //查询企业信息回显
    async getcompany() {
      const res = await companyDetail({ id: this.id })
      if (res.data.resultCode == 200) {
        this.companyinfo = res.data.data
      }
    },
    beforeAccountTypeDialog(type, id) {
      this.accountType = type
      this.currentId = id
      this.accountTypeDialogVisible = true
    },
    submitAccountTypeForm(formName) {
      // console.log(this.ruleForm.date, 'dete')
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      if (this.accountType == '主账号') {
        updateAccountAccess({
          id: this.currentId
        }).then(({ data: res }) => {
          console.log(res, 'res')
          if (res.resultCode === 200) {
            // this.afteraccountDialogHandle()
            this.getCorrelateUser()
            this.accountTypeDialogVisible = false
          } else {
            this.message.error(res.message)
          }
        })
      } else if (this.accountType == '子账号') {
        updateByPassAccountAccess({
          id: this.currentId
        }).then(({ data: res }) => {
          if (res.resultCode === 200) {
            // this.afteraccountDialogHandle()
            this.accountTypeDialogVisible = false
            this.getCorrelateUser()
          } else {
            this.message.error(res.message)
          }
        })
      }
      // } else {
      //   console.log('error submit!!')
      //   return false
      // }
      // })
    },
    //新增账号
    addaccount() {
      this.accountTypeDialogVisibles = true
      this.listAll()
    },
    afteraccountDialogHandle() {
      this.ruleForm.date = null
      this.accountDialogVisible = false
    },
    //用户认证通过企业会员短信
    userCertificate(row) {
      const query = {
        id: row.id,
        phone: row.phone
      }
      const that = this
      this.$alert('是否确认发送短信？')
        .then(function () {
          return userCertificate(query)
        })
        .then(() => {
          that.getCorrelateUser()
          that.$message.success('发送成功')
        })
    },
    submitForm(formName) {
      console.log(this.ruleForm.date, 'dete')
      this.$refs[formName].validate((valid) => {
        if (valid) {
          updateCompanyAccountCycle({
            beginTime: this.ruleForm.date[0],
            deadline: this.ruleForm.date[1],
            id: this.currentId
          }).then(({ data: res }) => {
            if (res.resultCode === 200) {
              this.afteraccountDialogHandle()

              this.getcompany()
            } else {
              this.message.error(res.message)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    beforeAccountDialog(id) {
      this.currentId = id
      this.accountDialogVisible = true
    },
    async getCorrelateUser() {
      const res = await getCorrelateUser(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list
      }
    },
    userinfoClick(item) {
      this.$router.push({
        name: 'userinfo',
        query: { id: item.wechatId }
      })
    },
    // 用户账号冻结/解冻
    async isFreeze(row) {
      const { data } = await isFreeze({
        userId: row.id
      })
      if (data.resultCode == 200) {
        this.$message({
          message: row.isFreeze ? '激活成功' : '冻结成功',
          type: 'success'
        })
        this.getCorrelateUser()
      }
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el_upload .el-form-item {
  margin-bottom: 0px;
}
::v-deep .name_form .el-input__suffix {
  position: absolute;
  height: 100%;
  right: -54px;
  top: 0;
  text-align: center;
  color: #c0c4cc;
  transition: all 0.3s;
  pointer-events: none;
}
::v-deep .el-step__title {
  font-size: 14px;
  line-height: 38px;
}

::v-deep .name_form .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 556px;
}
::v-deep .name_form .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 496px;
}

.associated {
  background: #ffffff;
  .img_commer {
    display: flex;
    border: 1px solid #146aff;
    border-radius: 39px;
    .name {
      font-size: 12px;
      line-height: 20px;
      color: #146aff;
    }
    .color_img {
      background: #146aff;
      height: 20px;
      width: 25px;
      border-radius: 20px;
      position: relative;
      left: -10px;
      img {
        width: 13px;
        height: 13px;
        position: relative;
        left: 5px;
        top: 2px;
      }
    }
  }
  .img_commers {
    display: flex;
    border: 1px solid #ff9c00;
    border-radius: 39px;
    margin: 0px 10px;
    .name {
      font-size: 12px;
      line-height: 20px;
      color: #ff9c00;
    }
    .color_img {
      background: #ff9c00;
      height: 20px;
      width: 25px;
      border-radius: 20px;
      position: relative;
      left: -10px;
      img {
        width: 13px;
        height: 13px;
        position: relative;
        left: 5px;
        top: 2px;
      }
    }
  }
  .associated_top {
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // flex-direction: column;
    // padding: 30px 0px 0px 0px;
    .account_cycle {
      text-align: right;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;

      /* 灰色字体 */

      color: #969495;
    }
    .associated_img {
      display: flex;
      align-items: center;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;

      color: #151515;
      img {
        width: 67px;
        height: 54px;
        border-radius: 5px;
      }
    }
  }
  .switch_tab {
    background: #ffffff;
    margin-top: 20px;

    .essential_information {
      padding: 30px 40px;
      .general_input {
        width: 845px;
        border: 1px solid #d4d4d4;
        padding: 14px 16px;

        .input_flex {
          display: flex;
          justify-content: space-between;
          padding: 7px 0px;
          span {
            padding-left: 15px;
            font-size: 20px;
            cursor: pointer;
          }
        }
      }
      .img_tips {
        position: relative;
        top: -10px;
        color: #6ba1ff;
      }

      h4 {
        color: #146aff;
      }
      .dialog-footer {
        display: flex;
        justify-content: center;
      }
    }
  }

  .label_management {
    padding: 10px 10px;

    .new_page {
      margin: 20px auto;
      width: 100%;
      text-align: center;
    }

    .input-with {
      width: 100%;
      overflow: hidden;
      padding: 20px 0px;

      .input-with-select {
        width: 400px;
        float: left;
      }

      .seach {
        background-color: #448aff;
        color: #fff;
        border-radius: 0;
        border: 1px solid #448aff;
      }
    }
  }
}
::v-deep .input_width .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 415px;
}
.business_card_img {
  display: block;
  margin-bottom: 5px;
}
</style>
